export enum THGCarPayoffModel {
    FIX = 'fix',
    FLEX = 'flex',
    EXPRESS = 'express',
}

export interface THGCarFixPayoffAmount {
    default: number;
    first?: number;
}

export interface THGCarFlexPayoffAmount {
    min: number;
    max: number;
}

export interface THGCarExpressPayoffAmount {
    default: number;
}
